import { Container, Stack, Typography } from "@mui/material"


export const Footer = () => {
    return (
        <Container>
            <Stack direction='row' marginTop='5rem' justifyContent='space-between' paddingX='2rem' paddingBottom='1rem'>
                <Typography>pregonesdidacpadilla.com</Typography>
                <Typography>© {new Date().getFullYear()}</Typography>
            </Stack>
        </Container>
    )
}