import { Container, Grid, Stack, Typography } from "@mui/material"
import { YoutubeVideo } from "./YoutubeVideo"
import { CENTER, LEFT, RIGHT } from "../constants"


export const PregonItem = ({ title, city, description, url, position = RIGHT }) => {

    if (position === CENTER) {
        return (
            <Stack direction='column'>
                <Typography variant='h4'>{title}</Typography>
                <Typography variant='h6' gutterBottom>{city}</Typography>
                <Typography variant='body1' marginBottom={2} align='justify'>{description}</Typography>
                <YoutubeVideo url={url} />
            </Stack>
        )
    }

    return (
        <Container>
            <Grid container spacing={10} alignItems='start'>
                {position === RIGHT &&
                    <Grid item xs={6}>
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <YoutubeVideo url={url} />
                        </div>
                    </Grid>
                }
                <Grid item xs={6}>
                    <Stack>
                        <Typography variant='h4' align={position === RIGHT ? LEFT : RIGHT}>{title}</Typography>
                        <Typography variant='h5' align={position === RIGHT ? LEFT : RIGHT} gutterBottom>{city}</Typography>
                        <Typography variant='body1' align={position === RIGHT ? LEFT : RIGHT}>{description}</Typography>
                    </Stack>
                </Grid>
                {position === LEFT &&
                    <Grid item xs={6}>
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <YoutubeVideo url={url} />
                        </div>
                    </Grid>
                }
            </Grid>
        </Container>
    )
}