import { Container, Stack, Typography } from "@mui/material"
import { pagesLabels } from "../PagesLabels"
import { useNavigate } from "react-router-dom"

const Navbar = () => {
    const navigate = useNavigate()

    return (
        <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: '10', backgroundColor: 'black' }}>
            <Container>
                <Stack direction='row' minHeight='4rem' justifyContent='space-between' alignItems='center' paddingX='2rem'>
                    <Typography variant="h5">Dídac Padilla</Typography>
                    <Stack direction='row' gap='1rem'>
                        {pagesLabels.map((page) => (
                            <Typography key={page.label} variant="h6" style={{ cursor: 'pointer' }} onClick={() => navigate(page.link)} >{page.label}</Typography>
                        ))}
                    </Stack>
                </Stack>
            </Container>
        </div>
    )
}

export default Navbar