import { linearFade } from "../Pages/HomePage"
import Arrow from "../Static/Arrow.png"
import { useMediaQuery } from "../customHooks/isMobile"

export const BannerImage = ({ image }) => {

    const mediaQuery = useMediaQuery()

    if (mediaQuery.isMobile) {
        return (
            <div style={{ marginBottom: '2rem', position: 'relative' }}>
                <div style={{ display: 'flex', justifyContent: 'ceter', overflow: 'hidden', maskImage: linearFade, WebkitMaskImage: linearFade }}>
                    <img alt='Dídac Padilla pregonando' style={{ height: '50vh', width: 'auto', minWidth: '100%', objectFit: 'cover' }} src={image} />
                </div>
            </div>
        )
    }

    return (
        <div style={{ marginBottom: '2rem', position: 'relative' }}>
            <div style={{ minHeight: '50vh', maxHeight: 'calc(100vh - 2rem)', overflow: 'hidden', maskImage: linearFade, WebkitMaskImage: linearFade }}>
                <img alt='Dídac Padilla pregonando' style={{ width: '100vw' }} src={image} />
            </div>
            <img alt='flecha apuntando hacia abajo' style={{ width: '3vw', backgroundColor: 'transparent', position: 'absolute', display: 'block', bottom: '2vh', left: 'calc(50vw - 2vw)' }} src={Arrow} />
        </div>
    )
}