import { Stack, Typography } from "@mui/material"
import { CENTER, LEFT, RIGHT } from "../constants"


export const YearTitle = ({ year, position = RIGHT }) => {
    if (position === CENTER) {
        return (
            <Typography variant="h3">{year}</Typography>
        )
    }

    return (
        <Stack paddingX='2rem' direction='row' gap='2rem' justifyContent='space-between'>
            {position === RIGHT && <div style={{ width: 'calc(50vw - 4rem)' }} />}
            <div style={{ width: 'calc(50vw - 4rem)' }}>
                <Typography variant="h3" align={(position === RIGHT) ? LEFT : RIGHT}>{year}</Typography>
            </div>
            {position === LEFT && <div style={{ width: 'calc(50vw - 4rem)' }} />}
        </Stack>
    )
}