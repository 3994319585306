import { Route, Routes } from "react-router-dom"
import HomePage from "./Pages/HomePage"
import { HomePageMobile } from "./Pages/HomePageMobile"
import { ContactoPage } from "./Pages/ContactoPage"
import { BiografiaPage } from "./Pages/BiografiaPage"

export const MyRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/contacto' element={<ContactoPage />} />
            <Route path='/biografia' element={<BiografiaPage />} />
        </Routes>
    )
}

export const MyRoutesMobile = () => {
    return (
        <Routes>
            <Route path='/' element={<HomePageMobile />} />
            <Route path='/contacto' element={<ContactoPage />} />
            <Route path='/biografia' element={<BiografiaPage />} />
        </Routes>
    )
}