import { Stack } from "@mui/material"
import Banner from "../Static/Banner.jpg"
import { linearFade } from "./HomePage"
import { YearTitle } from "../Components/YearTitle"
import { CENTER } from "../constants"
import { PregonItem } from "../Components/PregonItem"
import { pregones } from "../Pregones"

export const HomePageMobile = () => {

    const getPregones = () => {
        const elements = []
        for (let year in pregones) {
            elements.push(
                <>
                    <YearTitle year={year} position={CENTER} />
                    {pregones[year].map((pregon, index) => (
                        <PregonItem
                            key={index}
                            city={pregon.city}
                            title={pregon.title}
                            description={pregon.description}
                            url={pregon.url}
                            position={CENTER}
                        />
                    ))}
                </>
            )
        }

        return elements
    }

    return (
        <>
            <div style={{ marginBottom: '2rem', position: 'relative' }}>
                <div style={{ display: 'flex', justifyContent: 'ceter', overflow: 'hidden', maskImage: linearFade, WebkitMaskImage: linearFade }}>
                    <img alt='Dídac Padilla pregonando' style={{ height: '50vh', width: 'auto', objectFit: 'cover' }} src={Banner} />
                </div>
            </div>
            <div style={{ position: 'relative' }}>
                <Stack direction='column' gap='2rem' marginTop='4rem' marginLeft='4rem' marginRight='2rem'>
                    {getPregones()}
                </Stack>
                <div style={{ width: '.25rem', backgroundColor: 'white', height: 'calc(100% - .5rem)', position: 'absolute', top: '.5rem', left: '2rem' }} />
                <div style={{ width: '1.5rem', backgroundColor: 'white', height: '1.5rem', position: 'absolute', top: '0', left: '1.4rem', borderRadius: '50%' }} />
            </div>
            <div style={{ width: '100%' }}>
                <div style={{ width: '.25rem', height: '.25rem', backgroundColor: 'white', margin: '1rem auto 0 2rem' }} />
                <div style={{ width: '.25rem', height: '.25rem', backgroundColor: 'white', margin: '1rem auto 0 2rem' }} />
                <div style={{ width: '.25rem', height: '.25rem', backgroundColor: 'white', margin: '1rem auto 0 2rem' }} />
            </div>
        </>
    )
}