import { Instagram, MailOutline, WhatsApp } from "@mui/icons-material"
import { Container, Stack, Typography } from "@mui/material"
import { BannerImage } from "../Components/BannerImage"
import Banner3 from "../Static/Banner3.jpg"

export const ContactoPage = () => {
    return (
        <div>
            <BannerImage image={Banner3} />
            <Container style={{ marginTop: '2rem', textAlign: 'center', maxWidth: 600 }}>
                <Typography variant='h3' gutterBottom>Contacto</Typography>
                <Typography variant='body1'>Si quiere contactar conmigo para la realización de un pregón para su hermandad, asociación, cofradia, etc. tiene a su disposición varias vias por las cuales puede contactarme para este fin y poder acordar agenda y demás detalles.</Typography>
                <Stack direction='row' gap='2rem' marginTop='2rem' justifyContent='center'>
                    <a href="https://wa.me/34653129700">
                        <WhatsApp fontSize="large" style={{ color: 'white', cursor: 'pointer' }} />
                    </a>
                    <a href="mailto: didacpadilla20@gmail.com">
                        <MailOutline fontSize="large" style={{ color: 'white', cursor: 'pointer' }} />
                    </a>
                    <a href="https://www.instagram.com/magicdidac/">
                        <Instagram fontSize="large" style={{ color: 'white', cursor: 'pointer' }} />
                    </a>
                </Stack>
            </Container>
        </div>
    )
}