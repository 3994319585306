export const pagesLabels = [
  {
    label: 'Inicio',
    link: '/'
  },
  {
    label: 'Biografia',
    link: '/biografia'
  },
  {
    label: 'Contacto',
    link: '/contacto'
  }
]