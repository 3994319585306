import { Footer } from "./Components/Footer";
import Navbar from "./Components/Navbar";
import { NavbarMobile } from "./Components/NavbarMobile";
import { useMediaQuery } from "./customHooks/isMobile";
import { MyRoutes, MyRoutesMobile } from "./routes";

function App() {
  const mediaQuery = useMediaQuery()

  if (mediaQuery.isMobile) {
    return (
      <>
        <NavbarMobile />
        <MyRoutesMobile />
        <Footer />
      </>
    )
  }

  return (
    <>
      <Navbar />
      <div style={{ marginTop: '4rem' }}>
        <MyRoutes />
        <Footer />
      </div>
    </>
  )
}

export default App;