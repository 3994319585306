import { createTheme } from "@mui/material"

export const primaryColor = '#000'
export const secondaryColor = '#e0e0e0'

export const webTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: primaryColor,
            contrastText: '#012'
        },
        secondary: {
            main: secondaryColor,
            contrastText: primaryColor
        }
    },
    typography: {
        fontFamily: 'Ysabeau'
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    '@media (min-width: 1200px)': {
                        maxWidth: 1600
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: 'whitesmoke',
                    paddingBottom: '.5rem'
                }
            }
        }
    }
})
