import { Typography, IconButton, Menu, MenuItem, Stack } from "@mui/material"
import { Close, Menu as MenuIcon } from '@mui/icons-material'
import { useState } from "react"
import { pagesLabels } from "../PagesLabels"
import { useNavigate } from "react-router-dom"

export const NavbarMobile = () => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleToggle = (event) => {
        if (!anchorEl) {
            setAnchorEl(event.currentTarget)
        } else {
            setAnchorEl(null)
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: '10', backgroundColor: 'black' }}>
            <Stack direction='row' minHeight='4rem' justifyContent='space-between' alignItems='center' paddingX='1rem'>
                <Typography variant="h5">Dídac Padilla</Typography>
                <IconButton onClick={handleToggle} disableRipple>
                    {!open ? <MenuIcon style={{ color: 'white' }} fontSize="large" /> : <Close style={{ color: 'white' }} fontSize="large" />}
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {pagesLabels.map((page) => (
                        <MenuItem key={page.label}>
                            <Typography variant="h6" onClick={() => navigate(page.link)}>{page.label}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Stack>
        </div>
    )
}