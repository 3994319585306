import Banner from "../Static/Banner.jpg";
import Arrow from "../Static/Arrow.png"
import { Stack } from "@mui/material";
import { PregonItem } from "../Components/PregonItem";
import { YearTitle } from "../Components/YearTitle";
import { LEFT, RIGHT } from "../constants";
import { pregones } from "../Pregones";

export const linearFade = 'linear-gradient(rgba(0,0,0,1) 0%, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 95%)'
const getPosition = (b) => {
    return b ? RIGHT : LEFT
}

const HomePage = () => {

    const getItem = (year, position) => {
        return (
            <>
                <YearTitle year={year} position={getPosition(position)} />
                {pregones[year].map((pregon, index) => (
                    <PregonItem
                        key={index}
                        title={pregon.title}
                        city={pregon.city}
                        description={pregon.description}
                        url={pregon.url}
                        position={getPosition(position)}
                    />
                )
                )}
            </>
        )
    }

    const getPregones = () => {
        let lastPosition = false
        const elements = []
        for (let year in pregones) {
            lastPosition = !lastPosition
            elements.push(getItem(year, lastPosition))
        }

        return elements
    }

    return (
        <>
            <div style={{ marginBottom: '2rem', position: 'relative' }}>
                <div style={{ minHeight: '50vh', maxHeight: 'calc(100vh - 2rem)', overflow: 'hidden', maskImage: linearFade, WebkitMaskImage: linearFade }}>
                    <img alt='Dídac Padilla pregonando' style={{ width: '100vw' }} src={Banner} />
                </div>
                <img alt='flecha apuntando hacia abajo' style={{ width: '3vw', backgroundColor: 'transparent', position: 'absolute', display: 'block', bottom: '5vh', left: 'calc(50vw - 2vw)' }} src={Arrow} />
            </div>
            <div style={{ position: 'relative' }}>
                <Stack direction='column' gap='2rem' marginTop='4rem'>
                    {getPregones()}
                </Stack>
                <div style={{ width: '.25rem', backgroundColor: 'white', height: 'calc(100% - 1.5rem)', position: 'absolute', top: '1.5rem', left: 'calc(50% - .125rem)' }} />
                <div style={{ width: '1.5rem', backgroundColor: 'white', height: '1.5rem', position: 'absolute', top: '0', left: 'calc(50% - .75rem)', borderRadius: '50%' }} />
            </div>
            <div style={{ width: '100%', justifyContent: 'center', marginBottom: '5rem' }}>
                <div style={{ width: '.25rem', height: '1rem', backgroundColor: 'white', margin: '1rem auto 0 auto' }} />
                <div style={{ width: '.25rem', height: '1rem', backgroundColor: 'white', margin: '1rem auto 0 auto' }} />
                <div style={{ width: '.25rem', height: '1rem', backgroundColor: 'white', margin: '1rem auto 0 auto' }} />
            </div>
        </>
    )
}

export default HomePage