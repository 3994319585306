export const pregones = {
  "2021": [
    {
      title: "Semana Santa",
      city: "Mataró",
      description: "Primer pregón que elaboro y recito. Tengo el honor y el privilegio de pregonar la Semana Santa de mi ciudad, Mataró. Gracias a la organizadora, Hdad. Ntro. Padre Jesús Nazareno y Ntra. de la Esperanza, me conceden la oportunidad de poder iniciarme en el mundo de los pregones. En este pregón tuve la gran suerte de compartir escenario con Esencia Flamenca y recitar mis letras junto a sus sones.",
      url: "https://www.youtube.com/embed/y4uh1qzeazA?start=670"
    }
  ],
  "2022": [
    {
      title: "Glorias del Rocío",
      city: "Sabadell",
      description: "Pregón que tuvo lugar en la Parroquia de San Fransesc d'Asís de Sabadell. La Hdad. Ntra. Sra. del Rocío de Sabadell (hermandad filial nº123) me encomendó la realización de su pregón antes de la peregrinación a la romería de la Virgen del Rocío, un honor que me confiaran a mí tal responsabilidad.",
      url: "https://www.youtube.com/embed/iMxWufnPzfI?start=384"
    }
  ],
  "2023": [
    {
      title: "Semana Santa",
      city: "Mataró",
      description: "En 2023 se dio lugar a un pregón de pregoneros para anunciar la Semana Santa de Mataró. Realice un pregón de 8 minutos junto a mis compañeros pregoneros de otros años de esta misma localidad. Fue todo un honor poderle dedicar este pregón a mi amigo allí en el cielo. El pregón tuvo lugar en el teatro Monumental de la ciudad de Mataró.",
      url: "https://www.youtube.com/embed/8Hctx36rs2c?start=5710"
    },
    {
      title: "Cristo de la Paz",
      city: "Castelldefels",
      description: "Pregón de la Semana Santa de Castelldefels. La Hdad. Cristo de la Paz me concedió el honor de poner voz a su Semana Santa. Pregón que cuenta con la actuación de la A.M. Cristo de la Paz (de la propia hermandad) y de una saetera cantando conjuntamente con la agrupación musical “La Saeta”. En este pregón pongo voz a la marcha “Hermandad”  (marcha propia de la agrupación musical).",
      url: "https://www.youtube.com/embed/jJMK00EgXvs"
    }
  ],
  "2024": [
    {
      title: "Smo. Cristo de la Fe y Ntra. Sra. de los Dolores",
      city: "El Prat del Llobregat",
      description: "Pregón de la Semana Santa del Prat del Llobregat a cargo de la Cofradía del Santísimo Cristo de la Fe y Nuestra Señora de los Dolores de la localidad del Prat del Llobregat. El pregón contó con el acompañamiento musical de la Agrupación Musical Costaleros de Castelldefels, dónde interpretaron: 'Orando al Padre', 'Mi Cielo', 'Aliviamos tu dolor' (Estreno), 'Gitano de Sevilla' y 'La Saeta'. Con la marcha 'Gitano de Sevilla' se realizó un homenaje al maestro Rafael González-Serna (d.e.p.). El Pregón tuvo lugar en la parroquia de Sant Cosme i Sant Damià de la misma localidad. Agradecer a la cofradía la confianza puesta en mí para ser el impulsor de su pregón, que dejó de realizarse hacía 6 años.",
      url: "https://www.youtube.com/embed/1ciFNut4kbk?start=313"
    }
  ]
}