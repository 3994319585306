import { Container, Typography } from "@mui/material"
import Banner2 from "../Static/Banner2.jpg"
import { BannerImage } from "../Components/BannerImage"

export const BiografiaPage = () => {
    return (
        <div>
            <BannerImage image={Banner2} />
            <Container style={{ maxWidth: '1200px' }}>
                <Typography textAlign='justify' paragraph>Dídac Padilla Domínguez, natural de Mataró (Barcelona), nació el 2 de diciembre de 1998 en una familia humilde. A los 2 años de edad ya formaba parte de la Hermandad Ntro. Padre Jesús Nazareno y Ntra. Sra. de la Esperanza de Mataró, saliendo en solemne procesión Jueves Santo y Viernes Santo, acompañando al Señor por las calles de su ciudad.</Typography>
                <Typography textAlign='justify' paragraph>Empieza ayudando a su padre en el montaje del paso de palio de Ntra. Sra. De la Esperanza todos los años hasta la actualidad y a medida que pasa el tiempo ayuda en todo lo que puede a su Hermandad. Con 12 años de edad le ofrecen salir como contraguía auxiliar del paso de misterio en Viernes Santo, destacando su primera levantá en el centro de la ciudad de Mataró.</Typography>
                <Typography textAlign='justify' paragraph>Resalta por sus dotes en el ámbito informático y con ello empieza a realizar diversos carteles para su Hermandad, y llega a diseñar diversos carteles de Jueves Santo, años 2019, 2020, 2021, 2022 y 2023 entre otros. También empieza a crear videos para promocionar la Semana Santa de su ciudad destacando el año 2020, que con la pandemia, realizó 3 videos para los hermanos de la Hermandad y ayudó a que esta tradición siga siendo grande aún en esta situación extraordinaria.</Typography>
                <Typography textAlign='justify' paragraph>En el año 2017, a los 18 años de edad, procesiona finalmente como costalero de Ntro. Padre Jesús Nazareno, cumpliendo así uno de sus sueños. También participa como monitor de la diputación infantil enseñando a los niños más grandes las formas de andar y lo que significa ser cofrade. Con la llegada de Ntro. Padre Jesús Resucitado le otorgaron el cargo de prioste y actualmente sigue en ese cargo junto a su pareja (también prioste de N. P. Jesús Resucitado). En el año 2022 procesiona como uno de los voceros del paso de misterio de su Hermandad. Y en 2023 es uno de los impulsores para traer a su Hermandad una nueva talla mariana bajo la advocación de Ntra. Sra. de la Aurora, que procesionará el próximo Domingo de Resurrección junto a Ntro. Padre Jesús Resucitado por los más pequeños de la Hermandad. A finales de 2023 entra en la junta en funciones de su Hermandad con el cargo de secretario.</Typography>
                <Typography textAlign='justify' paragraph>En el año 2019 empezó a tratar con la Hermandad de Nuestra Señora del Rocío de Sabadell, Hermandad filial a la Hermandad de Almonte (123). Con ello empieza a interesarse también en el mundo Rociero y a generar vínculos con hermanos de dicha hermandad, destacando que en 2023 realiza su primer camino desandando los caminos de Doñana junto con esta Hermandad.</Typography>
                <Typography textAlign='justify' paragraph>En el ámbito académico, Dídac posee dos licenciaturas en Ingeniería Informática de Redes y Servicios y Diseño y Producción de Videojuegos, cursadas en Tecnocampus universidad adscrita a la Universidad Pompeu Fabra (UPF). En la actualidad trabaja como ingeniero informático en la empresa de joyería americana, en su sede en Europa.</Typography>
                <Typography variant="h4" gutterBottom marginTop='2rem'>Pregones</Typography>
                <Typography textAlign='justify' paragraph>Su primer pregón fue, el Pregón de Semana Santa de Mataró 2021, siendo el primero después del parón causado por la pandemia. En 2022, realizó el Pregón de las Glorias del Rocío de la Hermandad Ntra. Sra. del Rocío de Sabadell. En el año 2023 estuvo a cargo de una de las partes del Pregón de Pregoneros de la Semana Santa de Mataró y también se le encomendó la realización del pregón de Semana Santa de Castelldefels a cargo de la Hdad. Cristo de la Paz de dicha localidad. En este 2024 realizará el pregón de Semana Santa de la Cofradía del Santísimo Cristo de la Fe y Nuestra Señora de los Dolores de El Prat del Llobregat, el pregón de Semana Santa de la Cofradía de Ntro. Padre Jesús Nazareno y Ntra. Sra. de los Dolores de Badía del Vallès y la presentación del X Aniversario de la Agrupación Musical Sagrada Familia de Cornellá.</Typography>
            </Container>
        </div>
    )
}